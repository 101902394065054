import React from 'react';
import parse from 'html-react-parser';

import loadComponents from '../../Loadable';

const Alert = loadComponents('alert');

export default function Message({ message }) {
  return (
    <Alert
      info
      style={{
        marginBottom: `30px`,
      }}
    >
      {parse(message)}
    </Alert>
  );
}
